
import { arrayOf, object, string } from 'vue-types';
import { CONTEXT_DEFAULT } from '@/mixins/module';

const COMPONENTS = {
  visual: 'Visual',
  intro: 'Intro',
  productGrid: 'ProductGrid',
  contentGrid: 'ContentGrid',
  quote: 'Quote',
  accordion: 'AccordionModule',
  videoPlayer: 'VideoPlayerModule',
  recipeGrid: 'RecipeGrid',
  storyGrid: 'StoryGrid',
  richText: 'RichText',
  highlight: 'Highlight',
  anchorSection: 'AnchorSection',
};

export default {
  props: {
    modules: arrayOf(object()),
    extra: object(),
    spacing: string().def('mb-fluid-3xl-6xl'),
  },
  methods: {
    getComponent(module) {
      if (!COMPONENTS[module.type]) {
        console.error(`Component for module type '${module.type || module.__typename}' not found`);
      }

      return COMPONENTS[module.type];
    },
    getProps(module, last) {
      return {
        spacing: this.spacing,
        class: [
          `context-${this.extra?.context || CONTEXT_DEFAULT} `,
          last
            ? ''
            : module.type === 'visual'
              ? 'mb-fluid-2xl-3xl'
              : module.type === 'accordion'
                ? 'mb-fluid-4xl-5xl'
                : this.spacing,
        ],
        ...module,
        ...this.extra,
      };
    },
  },
};
